/**
 * @file Native Bridge Actions
 */

import { buildUrlFromPath } from '@@/bits/location'
import type { OrderedFilterState } from '@@/bits/surface_filter_state_manager'
import type { ActionMessage } from '@@/native_bridge/types'
import type { NativeAppOpenLink } from '@@/pinia/native_app'
import type {
  AccumulatedReactionData,
  Cid,
  Comment,
  DraftPost,
  Id,
  Post,
  PostAttributes,
  Section,
  User,
  UserId,
  WallReactionData,
} from '@@/types'
import type { Reaction } from '@padlet/arvo'

export const OPEN_LINK = 'open_link'
export function openLink({
  url,
  linkType,
  contentCategory,
  contentSubcategory,
  username,
}: NativeAppOpenLink): ActionMessage {
  return {
    message_type: OPEN_LINK,
    link_type: linkType,
    url,
    content_category: contentCategory,
    content_subcategory: contentSubcategory,
    username,
  }
}

export const EDIT_POST = 'edit_post'
export function editPost(postAttributes: PostAttributes, wishId?: Id): ActionMessage {
  return {
    message_type: EDIT_POST,
    postAttributes: {
      ...postAttributes,
      wish_id: wishId,
    },
  }
}

interface ExpandPostManifest {
  posts: { subject: string | null; url: string }
  post_index: number
}
/**
 * An expanded post manifest describes to the mobile app
 * how to display an expanded post. It also provides information
 * on how to iterate through all posts and expand each one of them.
 */
function createExpandedPostManifest({ expandedPost, wishFrameUrlTemplate, allPosts, wall }): ExpandPostManifest {
  const currentIndex = allPosts.findIndex((post) => {
    return expandedPost.id === post.id
  })
  const postManifests = allPosts.map((post) => {
    const url = wishFrameUrlTemplate
      .replace('_host_', window.location.hostname)
      .replace('_wallPublicKey_', wall.public_key)
      .replace('_wishId_', post.id.toString())
    return {
      subject: post.subject || null,
      url,
    }
  })
  return { posts: postManifests, post_index: currentIndex }
}

export const EXPAND_POST = 'show_expanded_post'
export function expandPost(expandedPostRequiredAttributes): ActionMessage {
  const { window, allPosts, expandedPost } = expandedPostRequiredAttributes
  const expandedPostIndex = allPosts.map((w) => w.id).indexOf(expandedPost.id)
  window.location.href = buildUrlFromPath(`/ios-app/expand-post?selected_index=${expandedPostIndex}`)

  // End Hack
  const expandedPostManifest = createExpandedPostManifest(expandedPostRequiredAttributes)
  return {
    message_type: EXPAND_POST,
    ...expandedPostManifest,
  }
}

export const PAGE_READY = 'page_ready'
export function pageReady(): ActionMessage {
  return {
    message_type: PAGE_READY,
  }
}

const TOUCHEND = 'touchend'
export function firstTouch({ isVerticalTouch }: { isVerticalTouch: boolean }): ActionMessage {
  return {
    message_type: TOUCHEND,
    is_first_touch: true,
    is_first_vertical_touch: isVerticalTouch,
  }
}

const OPEN_MAP_LOCATION_PICKER = 'open_map_location_picker'
export function openMapLocationPicker(): ActionMessage {
  return {
    message_type: OPEN_MAP_LOCATION_PICKER,
  }
}

const SHOW_COMMENTS_PANEL = 'show_comments_panel'
export function showCommentsPanel(postId: number): ActionMessage {
  return {
    message_type: SHOW_COMMENTS_PANEL,
    post: {
      id: postId,
    },
  }
}

const NATIVE_BRIDGE_READY = 'native_bridge_ready'
export function nativeBridgeReady(): ActionMessage {
  return {
    message_type: NATIVE_BRIDGE_READY,
  }
}

const UPDATE_ACTIVE_CONTRIBUTORS_LIST = 'update_active_contributors_list'
export function updateActiveContributorsList(users: Array<Partial<User>>): ActionMessage {
  return {
    message_type: UPDATE_ACTIVE_CONTRIBUTORS_LIST,
    users,
  }
}

/**
 * State for the actual padlet page that the native app can have access to.
 * Refer to https://github.com/padlet/rn-mobile-app/blob/9cd2561a0085e0ddf98af99969e4c1cc2b06c2e6/Padlet/app/features/padlet/models/WebViewSurfaceState.ts#L6-L27
 */
export interface SyncableSurfaceState {
  wall_sections: Section[]
  oauth_token: string
  has_comments: boolean
  reactions: Reaction[]
  accumulated_reactions: Record<Id, Pick<AccumulatedReactionData, 'reactionType' | 'sum' | 'totalReactionsCount'>>
  wall_reaction_data: WallReactionData
  expanded_post_data:
    | {
        posts: Array<{ id: Id; cid: Cid; has_attachment: boolean }>
        expanded_post?: Post & { attachment_frame_url: string; comments: Comment[] }
      }
    | {}
  is_remake_toggleable: boolean
  am_i_collaborator: boolean
  collaborator_just_left_user_id?: UserId
  can_i_write: boolean
  can_i_edit: boolean
  can_i_administer: boolean
  is_surface_v2_launched?: boolean
  post_drafts_by_cid: Record<Cid, DraftPost>
}

const POST_SURFACE_STATE = 'post_surface_state'
export function postSurfaceState(surfaceState: SyncableSurfaceState): ActionMessage {
  return {
    message_type: POST_SURFACE_STATE,
    surface_state: surfaceState,
  }
}

const OPEN_POST_REACTION_DIALOG = 'open_post_reaction_dialog'
export function openPostReactionDialog(postId: Id): ActionMessage {
  return {
    message_type: OPEN_POST_REACTION_DIALOG,
    post_id: postId,
  }
}

const SET_TITLE_BAR = 'set_title_bar'
export function setTitleBar(title: string): ActionMessage {
  return {
    message_type: SET_TITLE_BAR,
    title,
  }
}

const CHANGE_LANGUAGE = 'change_language'
export function changeLanguage(lang: string): ActionMessage {
  return {
    message_type: CHANGE_LANGUAGE,
    lang,
  }
}

const LEAVE_LIBRARY = 'leave_library'
export function leaveLibrary(): ActionMessage {
  return {
    message_type: LEAVE_LIBRARY,
  }
}

export enum UploadIntent {
  UserAvatar = 'user_avatar',
  LibraryAvatar = 'library_avatar',
  TenantAvatar = 'tenant_avatar',
}

interface UploadUserAvatarIntent {
  type: UploadIntent.UserAvatar
}

interface UploadLibraryAvatarIntent {
  type: UploadIntent.LibraryAvatar
  id: number
}

interface UploadTenantAvatarIntent {
  type: UploadIntent.TenantAvatar
  id: number
}

export type AppUploadIntent = UploadUserAvatarIntent | UploadLibraryAvatarIntent | UploadTenantAvatarIntent

const BROWSE_FILE = 'browse_file'
export function browserFile(data: AppUploadIntent): ActionMessage {
  return {
    message_type: BROWSE_FILE,
    data,
  }
}

const OPEN_CAMERA = 'open_camera'
export function openCamera(data: AppUploadIntent): ActionMessage {
  return {
    message_type: OPEN_CAMERA,
    data,
  }
}

export function openNativeCommentContentPicker(payload: { postId: number; commentId?: number }): ActionMessage {
  return {
    message_type: 'open_native_comment_content_picker',
    payload,
  }
}

export function openNativeCommentAudioRecorder(payload: { postId: number; commentId?: number }): ActionMessage {
  return { message_type: 'open_native_comment_audio_recorder', payload }
}

export function openNativeCommentVideoRecorder(payload: { postId: number; commentId?: number }): ActionMessage {
  return { message_type: 'open_native_comment_video_recorder', payload }
}

export function openNativeWhiteboardContentPicker(): ActionMessage {
  return { message_type: 'open_native_whiteboard_content_picker' }
}

export function cancelCommentAttachmentUpload(): ActionMessage {
  return { message_type: 'cancel_comment_attachment_upload' }
}

export function exitSlideshow(): ActionMessage {
  return {
    message_type: 'exit_slideshow',
  }
}

export function slideshowMounted(): ActionMessage {
  return {
    message_type: 'slideshow_mounted',
  }
}

export function shareSlideshow(): ActionMessage {
  return {
    message_type: 'share_slideshow',
  }
}

export function postFilterResults(postCount: number): ActionMessage {
  return {
    message_type: 'post_filter_results',
    payload: {
      postCount,
    },
  }
}

export function postFilterState(filters: OrderedFilterState): ActionMessage {
  return {
    message_type: 'post_filter_state',
    payload: {
      filters,
    },
  }
}

const CLOSE_SURFACE_ABUSE_REPORT_PANEL = 'close_surface_abuse_report_panel'
export function closeSurfaceAbuseReportPanel(): ActionMessage {
  return {
    message_type: CLOSE_SURFACE_ABUSE_REPORT_PANEL,
  }
}

export const SURFACE_ABUSE_REPORT_RESULT = 'surface_abuse_report_result'
export function surfaceAbuseReportResult(success: boolean): ActionMessage {
  return {
    message_type: SURFACE_ABUSE_REPORT_RESULT,
    success,
  }
}

interface ShowMentionSuggestionsPayload {
  // relative position of the text caret, used to position the mention popover near the caret
  position?: { top: number; left: number }
  // the string that the user has typed so far, used to filter the suggestions
  query?: string
}
export function showMentionSuggestions(payload: ShowMentionSuggestionsPayload): ActionMessage {
  return {
    message_type: 'show_mention_suggestions',
    payload,
  }
}

export function hideMentionSuggestions(): ActionMessage {
  return {
    message_type: 'hide_mention_suggestions',
  }
}
